import React from 'react';
import PropTypes from 'prop-types';
import {
  InputAdornment,
  IconButton,
  Paper,
  InputBase,
  TextField,
  FormControl,
  Input,
} from '@mui/material';
import { withTheme } from '@mui/styles';
import { Controller } from 'react-hook-form';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const styles = (theme) => ({
  textField: {
    width: '100%',
    fontFamily: 'IBM Plex Sans, sans-serif',
    lineHeight: 2,
    fontWeight: 400,
    fontSize: '1rem',
    backgroundColor: '#F3F6F9',
    border: 0,
    borderRadius: '8px',
    margin: 0,
    padding: 1.5,
    color: '#1A2027',
    '&.Mui-focused': {
      backgroundColor: '#F3F6F9',
    },
    '&.MuiInputBase-input': {
      backgroundColor: '#F3F6F9',
    },
  },
  input: {
    color: 'red',
    backgroundColor: ' black',
  },
});

const LoginPasswordTextField = (props) => {
  const { theme, name, placeholder, control, sx, ...restProps } = props;
  const style = styles(theme);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prevValue) => !prevValue);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Paper
      sx={{
        mb: 1,
        p: 1,
        boxShadow: 'none',
        backgroundColor: 'transparent',
      }}
    >
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, ref, onBlur } }) => (
          <TextField
            variant='standard'
            type={showPassword ? 'text' : 'password'}
            sx={{ ...sx }}
            placeholder={placeholder}
            size='large'
            value={value}
            onChange={onChange}
            {...restProps}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Paper>
  );
};

LoginPasswordTextField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
};
export default LoginPasswordTextField;
