import React, { lazy, useEffect, Suspense, Fragment } from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import theme from './theme';
import { Navigate } from 'react-router-dom';
import LoggedOutComponent from './logged_out/Main';
import { AppContext, useGlobalContext } from './globalAppContext';
import LoadingApp from './common/components/LoadingApp';

const LoggedInComponent = lazy(() => import('./components/Main'));
const LoggedOutWebsiteComponent = lazy(() =>
  import('./logged_out/WebsiteComponents/WebSiteMainPage')
);
const ForgotPassword = lazy(() => import('./logged_out/ForgotPassword'));
//combine with website
function App(props) {
  let { auth, fetchCurrentUser, getDayCloseData } =
    useGlobalContext(AppContext);
  const ProtectedRoute = ({ children }) => {
    return auth ? children : <LoggedOutWebsiteComponent />;
  };

  const CheckLogin = ({ children }) => {
    return auth ? <Navigate to='/' /> : children;
  };

  useEffect(() => {
    document.title = 'Ayyanar Fuels';
    (async () => {
      await fetchCurrentUser();
    })();
  }, []);

  useEffect(() => {
    if (auth) {
      getDayCloseData();
    }
  }, [auth]);

  if (auth === null) {
    return <LoadingApp />;
  } else {
    return (
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Suspense fallback={<Fragment />}>
            <Routes>
              <Route
                path='/*'
                element={
                  <ProtectedRoute>
                    <LoggedInComponent />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path='/login'
                element={
                  <CheckLogin>
                    <LoggedOutComponent />
                  </CheckLogin>
                }
              />
              <Route path='/forgot-password' element={<ForgotPassword />} />
            </Routes>
          </Suspense>
        </ThemeProvider>
      </BrowserRouter>
    );
  }
}

export default App;
