import { Alert, Box, Button } from '@mui/material';
import { makeStyles, withTheme } from '@mui/styles';
import React from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoginPageTextField from '../common/components/Input/LoginPageTextField';
import LoginPasswordTextField from '../common/components/Input/LoginPasswordTextField';
import { useNavigate } from 'react-router';
import { AppContext, useGlobalContext } from '../globalAppContext';
import { FaUserAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
const LoginPage = (props) => {
  const { theme } = props;
  const navigate = useNavigate();
  const { signInUser, fetchCurrentUser } = useGlobalContext(AppContext);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');

  // onsubmit - function
  const handleRegistration = async (data, event) => {
    event.preventDefault();

    let res = await signInUser(data);
    if (res.data.message === 'success') {
      const res = await fetchCurrentUser();
      if (res) {
        navigate('/dashboard');
      }
    } else {
      setAlertMessage(res.data.message);
      setAlertOpen(true);
    }
  };
  const classes = useStyles();
  // form validation rules
  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .max(50, 'Only 50 Characters Allowed')
      .required('Please Enter Username'),
    password: Yup.string()
      .max(15, 'maximum length 15')
      .required('Please Enter Password'),
  });
  const defaultValues = {
    username: '',
    password: '',
  };

  const {
    control,
    handleSubmit,

    formState: { errors, isValidating },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  React.useEffect(() => {
    if (Object.keys(errors).length === 0) {
      setAlertOpen(false);
    } else {
      setAlertOpen(true);
    }
  }, [errors, isValidating]);
  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.loginRoot}>
          <Box className={classes.headerImg}></Box>
          <Box className={classes.headerMobileContainer}>
            <Box className={classes.headerMobileImg}></Box>
          </Box>
          <Box className={classes.loginShadow}></Box>
          <Box className={classes.loginContainer}>
            <Box className={classes.loginContentBox}>
              <Box className={classes.loginBox}>
                <Box
                  className={classes.logo}
                  component='img'
                  src='/websiteImages/logo.png'></Box>
                <Box className={classes.inputSection}>
                  <form
                    onSubmit={handleSubmit(handleRegistration)}
                    id='loginForm'>
                    <LoginPageTextField
                      placeholder='Username'
                      name='username'
                      control={control}
                      sx={{
                        width: '280px',
                        marginTop: { xs: '10px', sm: 'auto' },
                      }}
                    />
                    <LoginPasswordTextField
                      placeholder='Password'
                      name='password'
                      control={control}
                      sx={{
                        width: '280px',
                        marginTop: { xs: '25px', sm: '5px' },
                      }}
                    />
                    {/* <Box sx={{ height: '45px' }}> */}
                    <Box
                      sx={{
                        width: '100%',
                        display: alertOpen ? 'flex' : 'none',
                        justifyContent: 'center',
                      }}>
                      <Alert severity='error' sx={{ backgroundColor: 'white' }}>
                        {errors.username
                          ? errors.username.message
                          : errors.password
                          ? errors.password.message
                          : alertMessage}
                      </Alert>
                    </Box>
                    {/* </Box> */}

                    <Button
                      variant='contained'
                      type='submit'
                      sx={{
                        width: '100px',
                        backgroundColor: '#ff8210',
                        borderRadius: '500px',
                        zIndex: 3,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        display: {
                          xs: 'none',
                          md: 'flex',
                          lg: 'flex',
                          sm: 'flex',
                          xl: 'flex',
                        },
                        '&:hover': {
                          backgroundColor: '#ff8210',
                        },
                      }}>
                      Sign In
                    </Button>
                    <Box
                      sx={{
                        position: 'relative',
                        top: '10px',
                        [theme.breakpoints.only('xs')]: {
                          top: 0,
                          marginBottom: '10px',
                        },
                      }}>
                      <Link className={classes.link} to={'/forgot-password'}>
                        Forgot password?
                      </Link>
                    </Box>
                    <Box className={classes.loginBtn}>
                      <Box className={classes.loginIcon}>
                        <FaUserAlt
                          style={{
                            color: '#ff8210',
                            border: '1px solid #ff8210',
                            borderRadius: '50%',
                            padding: '4px',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'white',
                          }}
                        />
                      </Box>
                      <Button
                        variant='contained'
                        type='submit'
                        sx={{
                          backgroundColor: '#ff8210',
                          width: '150px',
                          borderRadius: '500px',
                          height: '40px',
                          zIndex: 2,
                          paddingLeft: '60px',
                          fontSize: '18px',
                          right: '20px',
                          fontWeight: 600,
                          display: {
                            md: 'none',
                            lg: 'none',
                            xl: 'none',
                            sm: 'none',
                          },
                          '&:hover': {
                            backgroundColor: '#ff8210',
                          },
                        }}>
                        LOG IN
                      </Button>
                    </Box>
                  </form>
                </Box>
                <Box>
                  <a
                    href='https://efloz.com/'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <Box
                      sx={{ position: 'relative', top: alertOpen && '-20px' }}
                      className={classes.officeLogo}
                      component='img'
                      src='/websiteImages/login/eFloz_IT_logo.png'></Box>
                  </a>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.footerContainer}>
            <Box className={classes.footerImg}></Box>
          </Box>
          <Box className={classes.footerMobileContainer}>
            <Box className={classes.footerMobileImg}></Box>
          </Box>
          <a
            href='https://efloz.com/'
            target='_blank'
            rel='noopener noreferrer'
            className={classes.eflozLogo620Container}>
            <img
              className={classes.eflozLogo620}
              src='/images/eFloz_logo.png'
              alt=''
            />
          </a>
          <Box>
            <a
              href='https://efloz.com/'
              target='_blank'
              rel='noopener noreferrer'>
              <Box
                className={classes.officeITLogo}
                component='img'
                src='/websiteImages/login/eFloz_IT_logo.png'></Box>
            </a>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  headerImg: {
    backgroundImage: `url(/websiteImages/login/cornerImg.png)`,
    // position: 'relative',
    width: '455px',
    height: '290px',
    position: 'relative',
    marginRight: 'auto',
    marginBottom: 'auto',
    backgroundSize: 'cover',
    top: '-96px',
    right: '118px',
    transform: 'rotate(-29deg)',
    '@media screen and (max-width:1200px)': {
      backgroundImage: 'none',
      width: 0,
      height: 0,
    },
  },
  footerImg: {
    overflow: 'hidden',
    backgroundImage: `url(/websiteImages/login/cornerImg.png)`,
    // position: 'relative',
    width: '371px',
    height: '290px',
    position: 'relative',
    marginLeft: 'auto',
    marginTop: 'auto',
    backgroundSize: 'cover',
    left: '128px',
    transform: 'rotate(148deg)',
    bottom: '-75px',
    '@media screen and (max-width:1200px)': {
      backgroundImage: 'none',
      width: 0,
      height: 0,
    },
  },
  footerContainer: {
    overflow: 'hidden',
    marginTop: 'auto',
  },
  loginRoot: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'absolute',
  },
  loginShadow: {
    background: 'transparent',
    position: 'absolute',
    width: '913px',
    height: '385px',
    boxShadow: `1px -1px 8px 19px rgba(0,0,0,0.30),
    25px 0px 20px -20px rgba(0,0,0,0.30),
    0px 25px 20px -20px rgba(0,0,0,0.30),
    -25px 0px 20px -20px rgba(0,0,0,0.30)`,
    transform: `rotate(4deg)`,
    '@media screen and (max-width:1025px)': {
      width: '86%',
    },
    [theme.breakpoints.only('xs')]: {
      boxShadow: 'none',
      height: 'auto',
    },
  },
  loginContainer: {
    backgroundImage: `url(/websiteImages/login/login_bg.png)`,
    backgroundPosition: 'contain',
    position: 'absolute',
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'right',
    overflow: 'hidden',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    width: '950px',
    height: '450px',
    '@media screen and (max-width:1025px)': {
      width: '90%',
    },
    [theme.breakpoints.only('xs')]: {
      backgroundImage: 'none',
      boxShadow: 'none',
      width: '100%',
      display: 'block',
    },
  },
  loginContentBox: {
    width: '750px',
    height: '380px',
    backgroundColor: 'white',
    position: 'relative',
    borderRadius: '500px',
    marginLeft: '35%',
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  logo: {
    width: '220px',
    marginTop: '45px',
    marginBottom: '37px',
    [theme.breakpoints.only('xs')]: {
      width: '250px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  loginBox: {
    width: '488px',
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      width: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  officeLogo: {
    top: '7px',
    width: '65px',
    left: '170px',
    position: 'relative',
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  officeITLogo: {
    width: '100px',
    height: '60px',
    position: 'absolute',
    bottom: 0,
    right: 0,
    margin: '30px',
    cursor: 'pointer',
    [theme.breakpoints.only('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  inputSection: {
    width: '100%',
    textAlign: 'center',
    display: 'block',
  },
  loginIcon: {
    left: '25px',
    position: 'relative',
    // left: '70px',
    textAlign: 'center',
    zIndex: 6,
    width: '50px',
    height: '50px',
    padding: '3px',
    border: '1px solid white',
    borderRadius: '50%',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.only('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  headerMobileImg: {
    overflow: 'hidden',
    width: '214px',
    height: '290px',
    marginTop: '-68px',
    marginRight: '-52px',
    backgroundImage: `url(/websiteImages/login/mobileCorner.png)`,
    position: 'absolute',
    zIndex: 2,
    right: 0,
    top: 0,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    transform: 'rotate(109deg)',
    [theme.breakpoints.only('sm')]: {
      backgroundImage: 'none',
      width: 0,
      height: 0,
    },
    [theme.breakpoints.up('sm')]: {
      backgroundImage: 'none',
      width: 0,
      height: 0,
    },
  },
  footerMobileImg: {
    backgroundImage: `url(/websiteImages/login/mobileCorner.png)`,
    // position: 'relative',
    width: '214px',
    height: '290px',
    position: 'absolute',
    backgroundSize: 'cover',
    bottom: 0,
    left: 0,
    marginLeft: '-46px',
    marginBottom: '-92px',
    transform: 'rotate(124deg)',
    [theme.breakpoints.only('sm')]: {
      backgroundImage: 'none',
      width: 0,
      height: 0,
      position: 'relative',
    },
    [theme.breakpoints.up('sm')]: {
      backgroundImage: 'none',
      width: 0,
      height: 0,
      position: 'relative',
    },
  },
  headerMobileContainer: {
    overflow: 'hidden',
    position: 'absolute',
    [theme.breakpoints.only('xs')]: {
      position: 'unset',
    },
  },
  footerMobileContainer: {
    overflow: 'hidden',
    position: 'absolute',
    [theme.breakpoints.only('xs')]: {
      position: 'unset',
    },
  },
  link: {
    color: theme.palette.secondary.main,
    position: 'relative',
    display: 'inline-block',
    '&:before': {
      content: `''`,
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: 0,
      height: 2,
      backgroundColor: theme.palette.secondary.main,
      transition: 'width 0.3s ease-out',
      [theme.breakpoints.down('sm')]: {
        backgroundColor: '#fff',
      },
    },
  },
}));

export default withTheme(LoginPage);
