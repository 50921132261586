import React, { useState, useContext } from 'react';
import axios from 'axios';
import { addDays } from 'date-fns';

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [auth, setAuth] = useState(null);
  const [whatsappStatus, setWhatsappStatus] = useState(false);
  const [isDayCloseOpen, setIsDayCloseOpen] = useState(false);
  const [openDate, setCloseDate] = useState(null);
  const [tempOpenDate, setTempOpenDate] = useState(null);
  const [importFilter, setImportFilter] = useState({
    startDate: addDays(new Date(), -1),
    endDate: addDays(new Date(), -1),
  });
  const [salesFilter, setSalesFilter] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [productWiseFilter, setProductWiseFilter] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const [redeemDates, setRedeemDates] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  //-----------------for sample document download------------------
  //const baseUrl = 'http://localhost:5000';
  const baseUrl = 'https://ayyanarfuels.com';
  //--------------------------------------------------------------
  //USER
  async function fetchCurrentUser() {
    const res = await axios.get('/users/current_user', {
      credentials: 'include',
    });
    setAuth(res ? res.data : false);
    return res ? true : false;
  }

  // get Day Close
  async function getDayCloseData() {
    const result = await axios.get('/api/day-close');
    if (result?.data?.success) {
      const res = result?.data?.data;
      setCloseDate(
        res?.autoDayClose
          ? new Date(new Date().setDate(new Date().getDate() - res?.days))
          : result?.data?.data?.closeDate
      );
      const tempDate =
        new Date(res?.tempDateValidUpto).setHours(0, 0, 0, 0) <
        new Date().setHours(0, 0, 0, 0);

      const tempEqDate =
        new Date(res?.tempDateValidUpto).setHours(0, 0, 0, 0) ===
        new Date().setHours(0, 0, 0, 0);

      if (tempDate) {
        const updateResult = await axios.put(`/api/day-close/${res?._id}`, {
          ...res,
          tempDateValidUpto: null,
        });
        if (updateResult?.data?.success) {
          setTempOpenDate(null);
        }
      } else {
        setTempOpenDate(tempEqDate && res?.tempOpenDate);
      }
    }
  }

  async function logoutUser() {
    await axios.get('/users/signout');
    setAuth(false);
  }

  async function signInUser(data) {
    const res = await axios.post('/users/signin', data);
    return res;
  }

  return (
    <AppContext.Provider
      value={{
        auth,
        fetchCurrentUser,
        logoutUser,
        signInUser,
        whatsappStatus,
        setWhatsappStatus,
        baseUrl,
        isDayCloseOpen,
        setIsDayCloseOpen,
        openDate,
        tempOpenDate,
        getDayCloseData,
        importFilter,
        setImportFilter,
        salesFilter,
        setSalesFilter,
        productWiseFilter,
        setProductWiseFilter,
        redeemDates,
        setRedeemDates,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

//make sure use
export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
