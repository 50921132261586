import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Routing from './Routing'
import { Box, Grid } from '@mui/material'

const styles = (theme) => ({
  wrapper: {
    backgroundColor: theme.palette.common.white,
    overflowX: 'hidden',
    width: '98vw',
    height: '96vh',
    margin: '0px',
    padding: '0px',
  },
})

function Main(props) {
  const { classes } = props
  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            flexGrow: 1,
          }}
        >
          <Grid container spacing={2} sx={{ height: '100%' }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Routing />
            </Grid>
          </Grid>
        </Box>
      </div>
    </React.Fragment>
  )
}

Main.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(memo(Main))
