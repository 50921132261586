import React, { Fragment, useEffect } from 'react'
import { Box, Stack, Typography, Button, Grid, Alert } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import FilledTextField from '../common/components/Input/FilledTextField'
import FilledPassword from '../common/components/Input/FilledPassword'
import { AppContext, useGlobalContext } from '../globalAppContext'

function Login(props) {
  const navigate = useNavigate()
  const { signInUser, fetchCurrentUser } = useGlobalContext(AppContext)
  const [alertOpen, setAlertOpen] = React.useState(false)
  const [alertMessage, setAlertMessage] = React.useState('')

  // onsubmit - function
  const handleRegistration = async (data, event) => {
    event.preventDefault()

    let res = await signInUser(data)
    if (res.data.message === 'success') {
      const res = await fetchCurrentUser()
      if (res) {
        navigate('/dashboard')
      }
    } else {
      setAlertMessage(res.data.message)
      setAlertOpen(true)
    }
  }

  // form validation rules
  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .max(15, 'maximum length 15')
      .required('Please Enter Username'),
    password: Yup.string()
      .max(15, 'maximum length 15')
      .required('Please Enter Password'),
  })

  const defaultValues = {
    username: '',
    password: '',
  }

  const {
    control,
    handleSubmit,

    formState: { errors, isValidating },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues,
  })

  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      setAlertOpen(false)
    } else {
      setAlertOpen(true)
    }
  }, [errors, isValidating])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          m: 0,
          p: 0,
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <Grid container sx={{ width: 400, m: 'auto', p: 'auto' }}>
          <Grid
            item
            xs={12}
            sx={{
              ml: 'auto',
              mr: 'auto',
              mb: 10,
              P: 0,
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Inter, sans-serif',
                fontWeight: 600,
                width: '100%',
              }}
              variant='h3'
            >
              Ayyanar Fuels
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(handleRegistration)} id='loginForm'>
              <Stack sx={{ borderColor: 'blue', border: 0 }}>
                <FilledTextField
                  placeholder='Username'
                  name='username'
                  control={control}
                />
                <FilledPassword
                  placeholder='Password'
                  name='password'
                  control={control}
                />
                <Button
                  color='secondary'
                  type='submit'
                  variant='contained'
                  size='medium'
                  sx={{
                    ml: 0,
                    mr: 0,
                    mt: 5,
                    mb: 0,
                    pt: 2,
                    pb: 2,
                    pl: 0,
                    pr: 0,
                  }}
                >
                  Login
                </Button>
              </Stack>
              <Box
                sx={{
                  width: '100%',
                  mt: 10,
                  display: alertOpen ? 'flex' : 'none',
                }}
              >
                <Stack sx={{ width: '100%' }} spacing={2}>
                  <Alert severity='error'>
                    {errors.username
                      ? errors.username.message
                      : errors.password
                      ? errors.password.message
                      : alertMessage}
                  </Alert>
                </Stack>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Login
