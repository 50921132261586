import React, { memo } from 'react'
import { Routes, Route } from 'react-router-dom'
import Login from './Login'
import LoginPage from './LoginPage';

function Routing() {
  return (
    <Routes>
      <Route path='/' element={<LoginPage />} />
      <Route path='/login' element={<LoginPage />} />
    </Routes>
  );
}

export default memo(Routing)
