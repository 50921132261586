import React from 'react'
//import PropTypes from 'prop-types';

import { withTheme } from '@mui/styles'

function LoadingApp(props) {
  return <div>Loading...</div>
}

LoadingApp.propTypes = {}

export default withTheme(LoadingApp)
